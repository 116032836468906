export const rows = [
  {
    categoryName: 'Stammdaten',
    data: [
      {
        id: 'issuer',
        label: 'Fondsgesellschaft',
      },
      {
        id: 'isin',
        label: 'ISIN',
      },
      {
        id: 'peer_group_name',
        label: 'Peergroup',
      },
      {
        id: 'edition_date',
        label: 'Auflagedatum',
      },
      {
        id: 'ongoing_charge',
        label: 'Laufende jährliche Kosten',
      },
      {
        id: 'performance_fee',
        label: 'Performance Gebühr',
      },
      {
        id: 'minimum_investment',
        label: 'Mindestanlage',
      },
    ],
  },
  {
    categoryName: 'Smart Benchmarking',
    data: [
      {
        id: 'volume',
        label: 'Fondsvolumen',
      },
      {
        id: 'track_record',
        label: 'Track Record',
      },
      {
        id: 'return',
        label: 'Rendite',
      },
      {
        id: 'risk',
        label: 'Risiko',
      },
      {
        id: 'total_active_management',
        label: 'Aktives Management',
      },
      {
        id: 'tracking_error',
        label: 'Tracking error',
      },
      {
        id: 'management_affiliation',
        label: 'Managementzugeörigkeit',
      },
    ],
  },
  {
    categoryName: 'ESG',
    data: [
      {
        id: 'credibility_score',
        label: 'ESG Credibility',
      },
      {
        id: 'action_score_fund',
        label: 'ESG Action Score Fonds',
      },
      {
        id: 'action_score_firm',
        label: 'ESG Action Score Fondsgesellschaft',
      },
    ],
  },
];
