import { Option } from 'components/common/Select/Select.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';

export const PEER_GROUP = 'peergroups';

export const voteOptions: Option[] = [
  { value: 'buy', name: 'Buy' },
  { value: 'hold', name: 'Hold' },
  { value: 'switch', name: 'Switch' },
];

export const returnOptions: Option[] = [
  {
    value: 'Sehr gut',
    name: 'Sehr gut',
    chipVariant: TableChipVariant.VIBRANT_GREEN,
  },
  { value: 'Gut', name: 'Gut', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Schlecht', name: 'Schlecht', chipVariant: TableChipVariant.RED },
  {
    value: 'Sehr Schlecht',
    name: 'Sehr Schlecht',
    chipVariant: TableChipVariant.VIBRANT_RED,
  },
];

export const volumeOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.RED },
];

export const netFlows6MonthOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.RED },
];

export const trackingErrorOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.RED },
];

export const trackRecordOptions: Option[] = [
  { value: 'Lang', name: 'Lang', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Kurz', name: 'Kurz', chipVariant: TableChipVariant.RED },
];

export const riskOptions: Option[] = [
  {
    value: 'Sehr gut',
    name: 'Sehr gut',
    chipVariant: TableChipVariant.VIBRANT_GREEN,
  },
  { value: 'Gut', name: 'Gut', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Schlecht', name: 'Schlecht', chipVariant: TableChipVariant.RED },
  {
    value: 'Sehr Schlecht',
    name: 'Sehr Schlecht',
    chipVariant: TableChipVariant.VIBRANT_RED,
  },
];

export const activeManagementOptions: Option[] = [
  {
    value: 'Sehr gut',
    name: 'Sehr gut',
    chipVariant: TableChipVariant.VIBRANT_GREEN,
  },
  { value: 'Gut', name: 'Gut', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Schlecht', name: 'Schlecht', chipVariant: TableChipVariant.RED },
  {
    value: 'Sehr Schlecht',
    name: 'Sehr Schlecht',
    chipVariant: TableChipVariant.VIBRANT_RED,
  },
];

export const managementAffiliationOptions: Option[] = [
  { value: 'Lang', name: 'Lang', chipVariant: TableChipVariant.GREEN },
  {
    value: 'Mittel',
    name: 'Mittel',
    chipVariant: TableChipVariant.GREY,
  },
  { value: 'Kurz', name: 'Kurz', chipVariant: TableChipVariant.RED },
];

export const scoreOptions: Option[] = [
  { value: '0-20', name: '0% - 20%' },
  { value: '20-40', name: '>20% - 40%' },
  { value: '40-60', name: '>40% - 60%' },
  { value: '60-80', name: '>60% - 80%' },
  { value: '80-100', name: '>80% - 100%' },
];

export const articleOptions: Option[] = [
  { value: '0-20', name: '0 - 20' },
  { value: '20-40', name: '>20 - 40' },
  { value: '40-60', name: '>40 - 60' },
  { value: '60-80', name: '>60 - 80' },
  { value: '80-100', name: '>80 - 100' },
];

export const SFDROptions: Option[] = [
  { value: 'Artikel 6', name: 'Artikel 6' },
  { value: 'Artikel 8', name: 'Artikel 8' },
  { value: 'Artikel 9', name: 'Artikel 9' },
];

export const assetClassOptions: Option[] = [
  { value: 'Aktien', name: 'Aktien' },
  { value: 'Renten', name: 'Renten' },
  { value: 'Mischfonds', name: 'Mischfonds' },
  { value: 'Alternatives', name: 'Alternatives' },
  { value: 'ETFs', name: 'ETFs' },
];

export const crashDrawdownsOptions: Option[] = [
  {
    value: 'Sehr gut',
    name: 'Sehr gut',
    chipVariant: TableChipVariant.VIBRANT_GREEN,
  },
  { value: 'Gut', name: 'Gut', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Schlecht', name: 'Schlecht', chipVariant: TableChipVariant.RED },
  {
    value: 'Sehr Schlecht',
    name: 'Sehr Schlecht',
    chipVariant: TableChipVariant.VIBRANT_RED,
  },
];

export const loyaltyToStyleOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.RED },
];

export const assetConcentrationOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.RED },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.GREEN },
];
