import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
  FCSmartFundBenchmarkingTableProps,
  PeerGroup,
} from './FCSmartFundBenchmarkingTable.d';
import { ModuleLayout } from 'components/Modules/ModuleLayout';
import { Box } from 'components/common/Box';
import { useSearchParams } from 'react-router-dom';
import { SelectFilter } from 'components/Modules/Filters/Filters.d';
import {
  PEER_GROUP,
  SFDROptions,
  activeManagementOptions,
  assetClassOptions,
  managementAffiliationOptions,
  returnOptions,
  riskOptions,
  scoreOptions,
  trackRecordOptions,
  trackingErrorOptions,
  volumeOptions,
  voteOptions,
} from './config';
import { SelectChangeEvent } from '@mui/material';
import { Option } from 'components/common/Select/Select.d';
import { Filters } from 'components/Modules/Filters';
import { AddFeedbackModal } from 'components/common/Modals/AddFeedbackModal';
import { RemoveFromFundListModal } from 'components/common/Modals/RemoveFromFundListModal';
import { NameColumn } from 'components/Modules/NameColumn';
import { TableChip } from 'components/common/TableChip';
import { Score } from 'components/Modules/Score';
import { Actions } from 'components/Modules/Actions';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import {
  fetchAddFundsToWatchlistSmartBenchmarking,
  fetchFavoritesSmartBenchmarking,
  fetchSmartBenchmarking,
  fetchUpdateFundListStatusSmartBenchmarking,
  sendRequestReportSmartBenchmarking,
  sendRequestVoteSmartBenchmarking,
} from 'services/Modules';
import {
  CreatedRowSmartFundBenchmarking,
  SmartFundBenchmarkingColumn,
  SortModelProps,
} from 'components/common/CollapseTable/CollapseTable.d';
import { SmartFundBenchmarkingItemProps } from 'types/Modules.d';
import { Rank } from 'components/Modules/Rank';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { Vote } from 'components/Modules/Vote';
import { slugify } from 'utils/common';
import { Accordion } from 'components/common/Accordion';
import { SFBTable } from './SFBTable';
import {
  SmartBenchmarkingRequest,
  fetchRecommendationsForAction,
  fetchSmartBenchmarkingPeerGroup,
  fetchSmartBenchmarkingPeerGroups,
} from 'services/SmartBenchmarking';
import { COLORS } from 'theme/colors';
import { StyledTableChipContainer } from './FCSmartFundBenchmarkingTable.styled';
import { fetchUpdateComparisonListStatusSmartBenchmarking } from 'services/ComparisonList';
import { RemoveFromComparisonListModal } from 'components/common/Modals/RemoveFromComparisonListModal';

export const FCSmartFundBenchmarkingTable: FC<
  FCSmartFundBenchmarkingTableProps
> = ({ variant }) => {
  const isStandartVariant = variant === 'standart';
  const isRecommendationsVariant = variant === 'recommendations-for-action';
  const [searchValue, setSearchValue] = useState('');
  const [peerGroupSearchValue, setPeerGroupSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rows, setRows] = useState<CreatedRowSmartFundBenchmarking[]>([]);
  const [loading, setLoading] = useState(true);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const [isConfirmRemoveFromFundOpen, setIsConfirmRemoveFromFundOpen] =
    useState(false);
  const [
    isConfirmRemoveFromComparisonOpen,
    setIsConfirmRemoveFromComparisonOpen,
  ] = useState(false);
  const [isAddingFeedbackModalOpen, setIsAddingFeedbackModalOpen] =
    useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
    description: '',
  });
  const [peerGroupTabs, setPeerGroupTabs] = useState<PeerGroup[]>([]);

  const page = parseInt(searchParams.get('page') || '1', 10);
  const itemsPerPage = parseInt(searchParams.get('page-size') || '10', 10);
  const vote = searchParams.get('vote') || '';
  const asset_class = searchParams.get('asset_class') || '';
  const volume = searchParams.get('volume') || '';
  const trackRecord = searchParams.get('track_record') || '';
  const risk = searchParams.get('risk') || '';
  const returnFilter = searchParams.get('return') || '';
  const activeManagement = searchParams.get('active_management') || '';
  const credibilityScore = searchParams.get('credibility_score') || '';
  const trackingError = searchParams.get('tracking_error') || '';
  const managementAffiliation =
    searchParams.get('management_affiliation') || '';
  const sfdr = searchParams.get('sfdr') || '';
  const tab = searchParams.get('tab') || '';
  const isPeerGroupTab = slugify(tab) === PEER_GROUP;
  const peerGroup = searchParams.get('peer_group') || '';
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const thElements = document.querySelectorAll<HTMLTableHeaderCellElement>(
    '.collapse-table-head th',
  );

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const getDefaultFilterValue = (value: string, options: Option[]) => {
    const [result] = options.filter((option) => option.value === value);
    return result;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (tab && tab === 'Fonds') {
      searchParams.delete('peer_group');
      setSearchParams(searchParams);
    }
  }, [tab]);

  const filters: SelectFilter[] = [
    {
      value: getDefaultFilterValue(vote, voteOptions) || null,
      options: voteOptions,
      placeholder: 'Handlungsempfehlung',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('vote', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('vote');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value: getDefaultFilterValue(volume, volumeOptions) || null,
      options: volumeOptions,
      placeholder: 'Fondsvolumen',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('volume', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('volume');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value: getDefaultFilterValue(trackRecord, trackRecordOptions) || null,
      options: trackRecordOptions,
      placeholder: 'Track Record',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('track_record', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('track_record');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value: getDefaultFilterValue(returnFilter, returnOptions) || null,
      options: returnOptions,
      placeholder: 'Rendite',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('return', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('return');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value: getDefaultFilterValue(risk, riskOptions) || null,
      options: riskOptions,
      placeholder: 'Risiko',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('risk', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('risk');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value:
        getDefaultFilterValue(activeManagement, activeManagementOptions) ||
        null,
      options: activeManagementOptions,
      placeholder: 'Aktives Management',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('active_management', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('active_management');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value: getDefaultFilterValue(credibilityScore, scoreOptions) || null,
      options: scoreOptions,
      placeholder: 'ESG Credibility',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('credibility_score', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('credibility_score');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value:
        getDefaultFilterValue(
          managementAffiliation,
          managementAffiliationOptions,
        ) || null,
      options: managementAffiliationOptions,
      placeholder: 'Managementzugehörigkeit',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('management_affiliation', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('management_affiliation');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value: getDefaultFilterValue(trackingError, trackingErrorOptions) || null,
      options: trackingErrorOptions,
      placeholder: 'Tracking Error',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('tracking_error', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('tracking_error');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value: getDefaultFilterValue(sfdr, SFDROptions) || null,
      options: SFDROptions,
      placeholder: 'SFDR Klassifizierung',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('sfdr', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('sfdr');
        setSearchParams(searchParams);
        resetPage();
      },
    },
  ];

  const peerGroupsFilters: SelectFilter[] = [
    {
      value: getDefaultFilterValue(asset_class, assetClassOptions) || null,
      options: assetClassOptions,
      placeholder: 'Asset class',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('asset_class', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('asset_class');
        setSearchParams(searchParams);
        resetPage();
      },
    },
  ];

  const getActiveFiltersAmount = () =>
    [
      vote,
      volume,
      trackRecord,
      returnFilter,
      risk,
      activeManagement,
      credibilityScore,
      managementAffiliation,
      trackingError,
      sfdr,
      asset_class,
    ].filter((el) => el.length !== 0).length;

  const resetPage = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('page');
    setSearchParams(newSearchParams);
  };

  const onSubmitSearch = (value: string) => {
    isPeerGroupTab ? setPeerGroupSearchValue(value) : setSearchValue(value);
    resetPage();
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const createRow = (
    tableData: SmartFundBenchmarkingItemProps,
  ): CreatedRowSmartFundBenchmarking => {
    return {
      id: tableData.id,
      name: tableData.name,
      category: tableData.peer_group.name || '-',
      vote: tableData.recommendations_for_action
        ? tableData.recommendations_for_action?.vote
        : null,
      rank: tableData.rank ? tableData.rank.rank : null,
      nr_of_funds: tableData.rank ? tableData.rank.nr_of_funds : null,
      volume: tableData.volume,
      track_record: tableData.track_record,
      risk: tableData.total_risk_score,
      credibility_score:
        tableData.esg_scores && tableData.esg_scores?.credibility_score
          ? tableData.esg_scores?.credibility_score?.toString()
          : null,
      return: tableData.return_score.total_score,
      total_active_management: tableData.total_active_management_score,
      is_favoured: tableData.is_favoured,
      is_compared: tableData.is_compared,
      actions: '',
      leftMark: tableData.asset_class === 'ETFs' ? 'ETF' : null,
    };
  };

  const getTableHeadWidths = () => {
    const widths: number[] = [];

    thElements.forEach((th) => {
      const width = th.getBoundingClientRect().width;
      widths.push(width);
    });
    return widths;
  };

  const getTableData = async () => {
    let response;
    setLoading(true);
    const [credibility_min, credibility_max] = credibilityScore.split('-');
    const data: SmartBenchmarkingRequest = {
      page,
      page_size: itemsPerPage,
      ...(credibility_min ? { credibility_min } : {}),
      ...(credibility_max ? { credibility_max } : {}),
      ...(vote ? { vote } : {}),
      ...(volume ? { volume } : {}),
      ...(trackRecord ? { track_record: trackRecord } : {}),
      ...(returnFilter ? { return_value: returnFilter } : {}),
      ...(risk ? { risk } : {}),
      ...(activeManagement ? { active_management: activeManagement } : {}),
      ...(managementAffiliation
        ? { management_affiliation: managementAffiliation }
        : {}),
      ...(trackingError ? { tracking_error: trackingError } : {}),
      ...(sfdr ? { sfdr } : {}),
      ...(peerGroup ? { id: peerGroup } : {}),
      ...(sortModel && sortModel.sort_by && sortModel.sort_order
        ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
    };
    if (isStandartVariant || isRecommendationsVariant) {
      if (peerGroup.length !== 0) {
        response = await fetchSmartBenchmarkingPeerGroup(data);
      } else {
        response = isRecommendationsVariant
          ? await fetchRecommendationsForAction(data)
          : await fetchSmartBenchmarking(data);
      }
    } else {
      response = await fetchFavoritesSmartBenchmarking(data);
    }
    if (response && response.ok) {
      const tableData = response.parsedBody.results.funds;

      setRows(
        tableData.map((p: SmartFundBenchmarkingItemProps) => createRow(p)),
      );
      setResultsNumber(response.parsedBody.count);
      setLoading(false);
      if (peerGroup) {
        const section = document.getElementById(peerGroup);

        if (section) {
          section.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    }
  };

  useEffect(() => {
    if (isPeerGroupTab && !peerGroup) {
      getPeerGroupData();
      return;
    }
    getTableData();
  }, [
    page,
    itemsPerPage,
    searchValue,
    peerGroupSearchValue,
    vote,
    volume,
    trackRecord,
    risk,
    returnFilter,
    activeManagement,
    credibilityScore,
    trackingError,
    managementAffiliation,
    sfdr,
    peerGroup,
    sortModel,
    asset_class,
  ]);

  useEffect(() => {
    if (isPeerGroupTab && peerGroup) {
      getPeerGroupData();
    }
  }, [isPeerGroupTab, peerGroup]);

  const getPeerGroupData = async () => {
    const data = {
      ...(peerGroupSearchValue ? { search: peerGroupSearchValue } : {}),
      ...(asset_class ? { asset_class } : {}),
    };
    setLoading(true);
    const response = await fetchSmartBenchmarkingPeerGroups(data);
    if (response.ok) {
      const data = response.parsedBody;
      setPeerGroupTabs(
        data.map((item) => ({
          label: item.name,
          count: item.funds_count,
          id: item.id,
        })),
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const section = document.getElementById(peerGroup);

    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [peerGroupTabs, peerGroup]);

  const showConfirmRemoveFromFundListModal = (name: string, id: string) => {
    setIsConfirmRemoveFromFundOpen(true);
    setCurrentFund({ id, name, description: '' });
  };

  const showConfirmRemoveFromComparisonListModal = (
    name: string,
    id: string,
  ) => {
    setIsConfirmRemoveFromComparisonOpen(true);
    setCurrentFund({ id, name, description: '' });
  };

  const showAddFeedbackModal = (name: string, id: string) => {
    if (name) {
      setIsAddingFeedbackModalOpen(true);
      setCurrentFund({ id, name, description: '' });
    }
  };

  const changeFundListStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundListStatusSmartBenchmarking(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromFundOpen && setIsConfirmRemoveFromFundOpen(false);
      isConfirmRemoveFromFundOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds aus Ihrer Fondsliste entfernt"
              content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
            />,
          );
    }
  };

  const changeComparisonListStatus = async (id: string, name: string) => {
    const response = await fetchUpdateComparisonListStatusSmartBenchmarking(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromComparisonOpen &&
        setIsConfirmRemoveFromComparisonOpen(false);
      isConfirmRemoveFromComparisonOpen
        ? raiseToast.removeFromComparisonList(
            <Toast
              title="Fonds von Ihrer Vergleichsliste entfernt"
              content={`"${name}" wurde aus Ihrer Vergleichsliste entfernt.`}
            />,
          )
        : raiseToast.addToComparisonList(
            <Toast
              title="Fonds zu Ihrer Vergleichsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Vergleichsliste hinzugefügt.`}
            />,
          );
    }
  };

  const requestReport = async (id: string, name: string) => {
    const response = await sendRequestReportSmartBenchmarking(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für den FondsConsult Nachhaltigkeitsbericht wurde erfolgreich gesendet"
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  const requestVote = async (id: string, name: string) => {
    const response = await sendRequestVoteSmartBenchmarking(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für die FondsConsult Handlungsempfehlung wurde erfolgreich gesendet."
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  const columns: SmartFundBenchmarkingColumn[] = [
    {
      id: 'name',
      cellAlign: 'left',
      headAlign: 'left',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: true,
      label: 'Name',
      order: 0,
      minWidth: '5fr',
      maxWidth: '100%',
      renderCell: ({ row }) => (
        <NameColumn name={row.name} category={row.category || ''} />
      ),
    },
    {
      id: 'vote',
      headAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Handlungs-empfehlung',
      maxTextWidth: '83px',
      order: 0,
      minWidth: '130px',
      maxWidth: '130px',
      infoTooltip:
        'Handlungsempfehlunge n: BUY (Kaufen), HOLD (Halten), SWITCH (Austauschen). Diese Empfehlungen werden im FondsConsult Investment Committee diskutiert und beschlossen.',
      renderCell: ({ value, row }) => (
        <Vote
          value={value}
          onRequestVote={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            requestVote(row.id, row.name);
          }}
        />
      ),
    },
    {
      id: 'rank',
      headAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Rang',
      order: 0,
      minWidth: '77px',
      maxWidth: '77px',
      infoTooltip: 'Explanation info',
      renderCell: ({ row }) => (
        <Rank value={row.rank} maxValue={row.nr_of_funds} />
      ),
    },
    {
      id: 'volume',
      headAlign: 'center',
      cellAlign: 'center',
      dottedLeftBorder: true,
      isHighlighted: true,
      sortable: true,
      numeric: false,
      color: COLORS.info.selectedBorder,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Fonds-volumen',
      order: 0,
      minWidth: '133px',
      maxWidth: '133px',
      largeScreenWidth: '130px',
      infoTooltip:
        'Fondsgröße im Peer- Gruppen-Vergleich: Bewertung von "sehr klein" bis "sehr groß". Die Einstufung erfolgt relativ zur durchschnittlichen Fondsgröße der jeweiligen Vergleichsgruppe. Kategorien: sehr klein, klein, mittel, groß, sehr groß.',
      renderCell: ({ value }) => {
        const currentValue = getDefaultFilterValue(value, volumeOptions);
        return !value || value === '-' ? (
          <span>-</span>
        ) : (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <StyledTableChipContainer>
              <TableChip
                label={value.toLowerCase() as ChipVariant}
                tableChipVaraint={currentValue.chipVariant}
              />
            </StyledTableChipContainer>
          </Box>
        );
      },
    },
    {
      id: 'track_record',
      headAlign: 'center',
      cellAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      isHighlighted: true,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Track Record',
      color: COLORS.info.selectedBorder,
      maxTextWidth: '133px',
      order: 0,
      minWidth: '132px',
      maxWidth: '132px',
      largeScreenWidth: '130px',
      infoTooltip:
        'Track Record im Peer- Gruppen-Vergleich: Bewertung der Fondshistorie von "sehr kurz" bis "sehr lang". Die Einstufung erfolgt relativ zum durchschnittlichen „Track Record“ der Vergleichsgruppe. Kategorien: sehr kurz, kurz, mittel, lang, sehr lang.',
      renderCell: ({ value }) => {
        const currentValue = getDefaultFilterValue(value, trackRecordOptions);

        return !value || value === '-' ? (
          <span>-</span>
        ) : (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <StyledTableChipContainer>
              <TableChip
                label={value.toLowerCase() as ChipVariant}
                tableChipVaraint={currentValue.chipVariant}
              />
            </StyledTableChipContainer>
          </Box>
        );
      },
    },
    {
      id: 'return',
      headAlign: 'center',
      cellAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Rendite',
      isHighlighted: true,
      order: 0,
      minWidth: '110px',
      maxWidth: '110px',
      largeScreenWidth: '130px',
      infoTooltip: 'Explanation info',
      color: COLORS.info.selectedBorder,
      renderCell: ({ value }) => {
        const currentValue = getDefaultFilterValue(value, returnOptions);

        return !value || value === '-' ? (
          <span>-</span>
        ) : (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <StyledTableChipContainer>
              <TableChip
                label={value.toLowerCase() as ChipVariant}
                tableChipVaraint={currentValue.chipVariant}
              />
            </StyledTableChipContainer>
          </Box>
        );
      },
    },
    {
      id: 'risk',
      headAlign: 'center',
      cellAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Risiko',
      isHighlighted: true,
      order: 0,
      maxWidth: '100px',
      largeScreenWidth: '130px',
      color: COLORS.info.selectedBorder,
      minWidth: '100px',
      infoTooltip: 'Explanation info',
      renderCell: ({ value }) => {
        const currentValue = getDefaultFilterValue(value, riskOptions);

        return !value || value === '-' ? (
          <span>-</span>
        ) : (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <StyledTableChipContainer>
              <TableChip
                label={value.toLowerCase() as ChipVariant}
                tableChipVaraint={currentValue.chipVariant}
              />
            </StyledTableChipContainer>
          </Box>
        );
      },
    },
    {
      id: 'total_active_management',
      headAlign: 'center',
      cellAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Aktives Management',
      isHighlighted: true,
      order: 0,
      minWidth: '120px',
      maxWidth: '120px',
      largeScreenWidth: '130px',
      infoTooltip: 'Explanation info',
      color: COLORS.info.selectedBorder,
      renderCell: ({ value }) => {
        const currentValue = getDefaultFilterValue(
          value,
          activeManagementOptions,
        );
        return !value || value === '-' ? (
          <span>-</span>
        ) : (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <StyledTableChipContainer>
              <TableChip
                label={value.toLowerCase() as ChipVariant}
                tableChipVaraint={currentValue.chipVariant}
              />
            </StyledTableChipContainer>
          </Box>
        );
      },
    },
    {
      id: 'credibility_score',
      headAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'ESG Credibility',
      dottedLeftBorder: true,
      order: 0,
      minWidth: '100px',
      maxWidth: '100px',
      infoTooltip: 'Explanation info',
      renderCell: ({ value }) => <Score value={value} />,
    },
    {
      id: 'actions',
      headAlign: 'right',
      cellAlign: 'right',
      sortable: false,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Actions',
      order: 0,
      maxWidth: '135px',
      minWidth: '135px',
      renderCell: ({ row }) => (
        <Actions
          variant="short"
          isInMyFundList={row.is_favoured || false}
          isInComparisonList={row.is_compared || false}
          onChangeFundListHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            row.is_favoured
              ? showConfirmRemoveFromFundListModal(row.name, row.id)
              : changeFundListStatus(row.id, row.name);
          }}
          onChangeComparisonListHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            row.is_compared
              ? showConfirmRemoveFromComparisonListModal(row.name, row.id)
              : changeComparisonListStatus(row.id, row.name);
          }}
          onRequestReportHandler={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            requestReport(row.id, row.name);
          }}
          onFeedbackHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            showAddFeedbackModal(row.name, row.id);
          }}
        />
      ),
    },
  ];

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const addMultipleFundsToMyWatchlist = async () => {
    const response = await fetchAddFundsToWatchlistSmartBenchmarking(
      selectedRows,
    );
    if (response.ok) {
      raiseToast.addToFundList(
        'Fonds, die zu Ihrer Fondsliste hinzugefügt wurden.',
      );
      setSelectedRows([]);
      getTableData();
    }
  };

  const isAddMultipleFundsButtonEnabled = () => {
    return rows
      .filter((item) => selectedRows.includes(item.id))
      .some((row) => !row.is_favoured);
  };

  return (
    <>
      <RemoveFromFundListModal
        isShown={isConfirmRemoveFromFundOpen}
        toggle={() => setIsConfirmRemoveFromFundOpen(false)}
        onSubmit={() => changeFundListStatus(currentFund.id, currentFund.name)}
      />
      <RemoveFromComparisonListModal
        isShown={isConfirmRemoveFromComparisonOpen}
        toggle={() => setIsConfirmRemoveFromComparisonOpen(false)}
        onSubmit={() =>
          changeComparisonListStatus(currentFund.id, currentFund.name)
        }
      />
      <AddFeedbackModal
        withInterestLevel
        variant="smart-benchmarking"
        isShown={isAddingFeedbackModalOpen}
        currentFund={currentFund}
        toggle={() => setIsAddingFeedbackModalOpen(false)}
      />
      <ModuleLayout
        mb={isPeerGroupTab ? 2.5 : 5}
        withTabs={!isRecommendationsVariant}
        searchValue={isPeerGroupTab ? peerGroupSearchValue : searchValue}
        searchPlaceholder={
          isPeerGroupTab
            ? 'Suche über Peergroup Name'
            : 'Fondssuche über ISIN oder Name...'
        }
        activeFiltersAmount={getActiveFiltersAmount()}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
      >
        <Filters
          openFilters={openFilters}
          selectFilters={isPeerGroupTab ? peerGroupsFilters : filters}
        />

        {!isPeerGroupTab && (
          <SFBTable
            variant={variant}
            loading={loading}
            peerGroup={isPeerGroupTab}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            columns={columns}
            rows={rows}
            pagesNumber={getPagesNumber()}
            addMultipleFundsToMyWatchlist={addMultipleFundsToMyWatchlist}
            isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            loadingColumnWidth={getTableHeadWidths()}
          />
        )}
      </ModuleLayout>
      {isPeerGroupTab && (
        <Box px={2.2} pb={5}>
          <Accordion
            onTabChange={() => setSelectedRows([])}
            tabs={peerGroupTabs.map((tab) => ({
              label: tab.label,
              count: tab.count,
              id: tab.id,
              content: (
                <SFBTable
                  variant={variant}
                  loading={loading}
                  peerGroup={isPeerGroupTab}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  columns={columns}
                  rows={rows}
                  pagesNumber={getPagesNumber()}
                  isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
                  addMultipleFundsToMyWatchlist={addMultipleFundsToMyWatchlist}
                  sortModel={sortModel}
                  onSortModelChange={handleSortModelChange}
                  loadingColumnWidth={getTableHeadWidths()}
                />
              ),
            }))}
          />
        </Box>
      )}
    </>
  );
};
