export const AppPaths = {
  login: '/login',
  signUp: 'sign-up',
  main: '/',
  passwordResetRequest: '/password-reset-request',
  resetPasswordLinkSent: '/reset-password-link-sent',
  changePassword: '/reset-password',
  resetPasswordConfirmed: '/reset-password-confirmed',
  contuctUs: '/contact-us',
  esgCredibilityActionScore: '/esg-credibility-action-score',
  fcSmartFundBenchmarking: '/fc-smart-fund-benchmarking',
  fondsConsultStudies: '/fonds-consult-studies',
  privateMarketsRealEstate: '/private-markets-real-estate',
  recommendationsForActions: '/recommendations-for-actions',
  myWatchlist: '/my-watchlist',
  comparisonList: '/comparison-list',
  mlpDepots: '/mlp-depots',
};
