import { FC } from 'react';
import { ExpandableRowProps } from './ExpandableRow.d';
import { Box, Collapse, TableRow } from '@mui/material';
import {
  StyledExpandableRowTableCell,
  StyledHeadTableCell,
  StyledHeadTableCellContainer,
  StyledTableBody,
  StyledTableCell,
  StyledTableCellContainer,
  StyledTable,
} from './ExpandableRow.styled';
import { CreatedColumnComparisonList } from '../VerticalCollapseTable.d';
import { addDotsToString } from 'utils/common';

const MAX_LENGTH = 95;

export const ExpandableRow: FC<ExpandableRowProps> = ({
  isDifferencesHighlighted,
  open,
  data,
  row,
  columns,
}) => {
  const pinnedColumn = columns[0];

  const isHighlighted = (row: string) => {
    return columns.some(
      (item) =>
        pinnedColumn &&
        pinnedColumn[row as keyof CreatedColumnComparisonList]?.toString() !==
          item[row as keyof CreatedColumnComparisonList]?.toString(),
    );
  };

  return (
    <TableRow>
      <StyledExpandableRowTableCell colSpan={columns.length + 2}>
        <Collapse in={open[row]} timeout="auto" unmountOnExit>
          <Box>
            <StyledTable>
              <StyledTableBody>
                {data.map((insideRow) => (
                  <TableRow key={insideRow.label}>
                    <StyledHeadTableCell
                      component="th"
                      scope="row"
                      align="left"
                      width="244px"
                    >
                      <StyledHeadTableCellContainer
                        isHighlighted={
                          isDifferencesHighlighted &&
                          isHighlighted(insideRow.id)
                        }
                      >
                        {insideRow.label}
                      </StyledHeadTableCellContainer>
                    </StyledHeadTableCell>
                    {columns.map((item, idx) => (
                      <StyledTableCell
                        key={`${item[insideRow.id]}-${idx}`}
                        align="left"
                        width="325px"
                      >
                        <StyledTableCellContainer
                          isHighlighted={
                            isDifferencesHighlighted &&
                            isHighlighted(insideRow.id)
                          }
                        >
                          {item[insideRow.id].length >= MAX_LENGTH
                            ? addDotsToString(item[insideRow.id], MAX_LENGTH)
                            : item[insideRow.id]}
                        </StyledTableCellContainer>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))}
              </StyledTableBody>
            </StyledTable>
          </Box>
        </Collapse>
      </StyledExpandableRowTableCell>
    </TableRow>
  );
};
