import { FC, useState } from 'react';
import { TableHeaderWithActionsProps } from './TableHeaderWithActions.d';
import { Box } from 'components/common/Box';
import {
  StyledMoreActionsButton,
  StyledName,
  StyledPoint,
} from './TableHeaderWithActions.styled';
import { ReactComponent as MoreActionsIcon } from 'assets/Icons/more-actions.svg';
import { COLORS } from 'theme/colors';
import { ExpandedActions } from 'components/Modules/Actions/ExpandedActions';

export const TableHeaderWithActions: FC<TableHeaderWithActionsProps> = ({
  data,
  requestReport,
  showConfirmRemoveFromFundListModal,
  changeFundListStatus,
  showAddFeedbackModal,
  showConfirmRemoveFromComparisonListModal,
  changePinnedColumn,
}) => {
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] =
    useState<null | HTMLElement>(null);
  const openMoreActions = Boolean(moreActionsAnchorEl);

  const handleClickMoreActions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setMoreActionsAnchorEl(event.currentTarget);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      gap={1}
    >
      <Box display="flex" gap={1.5}>
        <StyledPoint color={data?.color || COLORS.background.neutral} />
        <StyledName variant="body">{data?.name || '-'}</StyledName>
      </Box>
      <StyledMoreActionsButton onClick={handleClickMoreActions}>
        <MoreActionsIcon
          stroke={
            openMoreActions ? COLORS.accent.primary : COLORS.typography.main
          }
        />
      </StyledMoreActionsButton>
      <ExpandedActions
        open={openMoreActions}
        isInMyFundList={data?.isInFundList || false}
        isInComparisonList
        setAnchorEl={setMoreActionsAnchorEl}
        anchorEl={moreActionsAnchorEl}
        onDownloadHandler={(e: React.BaseSyntheticEvent) => {
          e.stopPropagation();
          requestReport(data.id, data.name);
        }}
        onChangeFundListHandle={(e: React.BaseSyntheticEvent) => {
          e.stopPropagation();
          data.isInFundList
            ? showConfirmRemoveFromFundListModal(data.id, data.name)
            : changeFundListStatus(data.id, data.name);
        }}
        onFeedbackHandle={(e: React.BaseSyntheticEvent) => {
          e.stopPropagation();
          showAddFeedbackModal(data.id, data.name);
        }}
        onChangeComparisonListHandle={(e: React.BaseSyntheticEvent) => {
          e.stopPropagation();
          showConfirmRemoveFromComparisonListModal(data.id, data.name);
        }}
        onChangePinnedColumnHandle={(e: React.BaseSyntheticEvent) => {
          e.stopPropagation();
          changePinnedColumn(data);
        }}
      />
    </Box>
  );
};
