import { FC, useEffect, useState } from 'react';
import {
  SearchContainer,
  StyledContainer,
} from './ComparisonListContent.styled';
import { Search } from 'components/common/Search';
import { Box } from 'components/common/Box';
import { Switch } from 'components/common/Switch';
import { ComparisonListChart } from '../ComparisonListChart';
import { rows } from './config';
import { VerticalCollapseTable } from 'components/common/VerticalCollapseTable';
import { CreatedColumnComparisonList } from 'components/common/VerticalCollapseTable/VerticalCollapseTable.d';
import { ComparisonListItemProps } from 'types/ComparisonList.d';
import {
  fetchSmartBenchmarkingComparisonList,
  fetchUpdateComparisonListStatusSmartBenchmarking,
} from 'services/ComparisonList';
import { Typography } from 'components/common/Typography';
import { format } from 'date-fns';
import { formatCurrency, formatNumberWithComma } from 'utils/common';
import {
  fetchUpdateFundListStatusSmartBenchmarking,
  sendRequestReportSmartBenchmarking,
} from 'services/Modules';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import { AddFeedbackModal } from 'components/common/Modals/AddFeedbackModal';
import { RemoveFromFundListModal } from 'components/common/Modals/RemoveFromFundListModal';
import { RemoveFromComparisonListModal } from 'components/common/Modals/RemoveFromComparisonListModal';
import { backgroundColors } from '../ComparisonListChart/config';

export const ComparisonListContent: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [columns, setColumns] = useState<CreatedColumnComparisonList[]>([]);
  const [isDifferencesHighlighted, setIsDifferencesHighlighted] =
    useState(false);
  const [isAddingFeedbackModalOpen, setIsAddingFeedbackModalOpen] =
    useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
  });
  const [isConfirmRemoveFromFundOpen, setIsConfirmRemoveFromFundOpen] =
    useState(false);
  const [
    isConfirmRemoveFromComparisonOpen,
    setIsConfirmRemoveFromComparisonOpen,
  ] = useState(false);
  const [pinnedColumn, setPinnedColumn] = useState<CreatedColumnComparisonList>(
    columns[0],
  );

  useEffect(() => {
    setPinnedColumn(columns[0]);
  }, [columns]);

  const changePinnedColumn = (column: CreatedColumnComparisonList) => {
    setPinnedColumn(column);
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDifferencesHighlighted(event.target.checked);
  };

  const requestReport = async (id: string, name: string) => {
    const response = await sendRequestReportSmartBenchmarking(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für den FondsConsult Nachhaltigkeitsbericht wurde erfolgreich gesendet"
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createColumn = (
    tableData: ComparisonListItemProps,
    index: number,
  ): CreatedColumnComparisonList => {
    return {
      id: tableData.id,
      name: tableData.name,
      track_record: tableData.track_record || '-',
      isin: tableData.isin,
      edition_date: tableData.edition_date
        ? format(tableData.edition_date, 'dd.MM.yyyy')
        : '-',
      volume: tableData.volume || '-',
      vote: tableData.recommendations_for_action?.vote || '-',
      rank: tableData.rank?.rank || 0,
      risk: tableData.total_risk_score || '-',
      return: tableData.return_score?.total_score?.label || '-',
      return_score: tableData.return_score,
      volatility_score: tableData.volatility_score,
      total_active_management: tableData.total_active_management_score || '-',
      credibility_score:
        tableData.esg_scores?.credibility_score?.toString() || '-',
      action_score_firm:
        tableData.esg_scores?.action_score_firm?.toString() || '-',
      action_score_fund:
        tableData.esg_scores?.action_score_fund?.toString() || '-',
      issuer: tableData.issuer || '-',
      ongoing_charge: tableData.ongoing_charge
        ? `${formatNumberWithComma(tableData.ongoing_charge)} %`
        : '-',
      performance_fee: tableData.performance_fee
        ? `${tableData.performance_fee} %`
        : '-',
      minimum_investment:
        tableData.min_investment && tableData.min_investment_currency
          ? formatCurrency(
              tableData.min_investment,
              tableData.min_investment_currency,
            )
          : '-',
      isInFundList: tableData.is_favoured,
      isInComparison: true,
      color: backgroundColors[index],
      peer_group_name: tableData.peer_group_name || '-',
      tracking_error: tableData.tracking_error || '-',
      management_affiliation: tableData.management_affiliation || '-',
    };
  };

  const getData = async () => {
    const response = await fetchSmartBenchmarkingComparisonList();
    if (response.ok) {
      const tableData = response.parsedBody;
      setColumns(tableData.map((data, index) => createColumn(data, index)));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const showAddFeedbackModal = (id: string, name: string) => {
    if (name) {
      setIsAddingFeedbackModalOpen(true);
      setCurrentFund({ id, name });
    }
  };

  const showConfirmRemoveFromFundListModal = (id: string, name: string) => {
    setIsConfirmRemoveFromFundOpen(true);
    setCurrentFund({ id, name });
  };

  const showConfirmRemoveFromComparisonListModal = (
    id: string,
    name: string,
  ) => {
    setIsConfirmRemoveFromComparisonOpen(true);
    setCurrentFund({ id, name });
  };

  const changeFundListStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundListStatusSmartBenchmarking(id);
    if (response.ok) {
      getData();
      isConfirmRemoveFromFundOpen && setIsConfirmRemoveFromFundOpen(false);
      isConfirmRemoveFromFundOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds aus Ihrer Fondsliste entfernt"
              content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
            />,
          );
    }
  };

  const changeComparisonListStatus = async (id: string, name: string) => {
    const response = await fetchUpdateComparisonListStatusSmartBenchmarking(id);
    if (response.ok) {
      getData();
      columns[0] && changePinnedColumn(columns[0]);
      isConfirmRemoveFromComparisonOpen &&
        setIsConfirmRemoveFromComparisonOpen(false);
      raiseToast.removeFromComparisonList(
        <Toast
          title="Fonds von Ihrer Vergleichsliste entfernt"
          content={`"${name}" wurde aus Ihrer Vergleichsliste entfernt.`}
        />,
      );
    }
  };

  return (
    <>
      <AddFeedbackModal
        withInterestLevel
        variant="smart-benchmarking"
        isShown={isAddingFeedbackModalOpen}
        currentFund={currentFund}
        toggle={() => setIsAddingFeedbackModalOpen(false)}
      />
      <RemoveFromFundListModal
        isShown={isConfirmRemoveFromFundOpen}
        toggle={() => setIsConfirmRemoveFromFundOpen(false)}
        onSubmit={() => changeFundListStatus(currentFund.id, currentFund.name)}
      />
      <RemoveFromComparisonListModal
        isShown={isConfirmRemoveFromComparisonOpen}
        toggle={() => setIsConfirmRemoveFromComparisonOpen(false)}
        onSubmit={() =>
          changeComparisonListStatus(currentFund.id, currentFund.name)
        }
      />
      {columns.length === 0 ? (
        <Box pt={5} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body">Keine Fonds gefunden</Typography>
        </Box>
      ) : (
        <StyledContainer pt={5} pb={22}>
          <Box
            px={5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SearchContainer>
              <Search
                initialValue={searchValue}
                onSubmit={onSubmitSearch}
                placeholder="Fondssuche über ISIN oder Name..."
              />
            </SearchContainer>
            <Switch
              checked={isDifferencesHighlighted}
              onChange={handleSwitchChange}
              label="Unterschiede Hervorheben"
            />
          </Box>
          <Box pl={5} pt={2.5}>
            <VerticalCollapseTable
              isDifferencesHighlighted={isDifferencesHighlighted}
              tableData={{
                columns,
                rows,
              }}
              pinnedColumn={pinnedColumn}
              showConfirmRemoveFromFundListModal={
                showConfirmRemoveFromFundListModal
              }
              changeFundListStatus={changeFundListStatus}
              requestReport={requestReport}
              showAddFeedbackModal={showAddFeedbackModal}
              showConfirmRemoveFromComparisonListModal={
                showConfirmRemoveFromComparisonListModal
              }
              changePinnedColumn={changePinnedColumn}
            />
          </Box>
          <Box px={5} pt={5}>
            <ComparisonListChart data={columns} />
          </Box>
        </StyledContainer>
      )}
    </>
  );
};
