import { FC } from 'react';
import { ESGFiltersModalProps } from './ESGFiltersModal.d';
import { RangeSlider } from 'components/common/RangeSlider';
import { StyledContainer, StyledFilterItem } from './ESGFiltersModal.styled';
import { useSearchParams } from 'react-router-dom';
import { SlideOutFilterModal } from 'components/common/Modals/SlideOutFilterModal';

export const ESGFiltersModal: FC<ESGFiltersModalProps> = ({
  filters,
  isShow,
  clearFilters,
  closeModal,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const handleRangeChange = (id: string, values: number[]) => {
    const newValue = values.join('-');
    searchParams.set(id, newValue);
    setSearchParams(searchParams);
    resetPage();
  };

  const getCurrentValues = (value: string) => {
    if (value) {
      return value.split('-').map(Number);
    }
    return undefined;
  };

  const handleClearAllFilters = () => {
    const newSearchParams = new URLSearchParams();
    filters.forEach(({ id }) => newSearchParams.delete(id));
    setSearchParams(newSearchParams);

    clearFilters();
    window.location.reload();
  };

  return (
    <SlideOutFilterModal
      isShown={isShow}
      closeModal={closeModal}
      clearFilters={handleClearAllFilters}
      applyFilters={closeModal}
    >
      <StyledContainer>
        {filters.map(({ id, label, value }) => (
          <StyledFilterItem key={id}>
            <RangeSlider
              label={label}
              min={0}
              max={100}
              step={5}
              currentValues={getCurrentValues(value)}
              onChange={(values) => handleRangeChange(id, values)}
            />
          </StyledFilterItem>
        ))}
      </StyledContainer>
    </SlideOutFilterModal>
  );
};
