import { FC } from 'react';
import { Login } from 'views/User/Login/Login';
import { PasswordResetRequest } from 'views/User/ForgotPassword/PasswordResetRequest/PasswordResetRequest';
import { ResetPasswordLinkSent } from 'views/User/ForgotPassword/ResetPasswordLinkSent/ResetPasswordLinkSent';
import { AppPaths } from 'urls/frontend';
import { MainPage } from 'views/MainPage/MainPage';
import { ChangePassword } from 'views/User/ForgotPassword/ChangePassword/ChangePassword';
import { ResetPasswordConfirmed } from 'views/User/ForgotPassword/ResetPasswordConfirmed/ResetPasswordConfirmed';
import { ContactUs } from 'views/ContactUs/ContactUs';
import { ESGCredibilityActionScore } from 'views/Modules/ESGCredibilityActionScore/ESGCredibilityActionScore';
import { FCSmartFundBenchmarking } from 'views/Modules/FCSmartFundBenchmarking/FCSmartFundBenchmarking';
import { FondsConsultStudies } from 'views/Modules/FondsConsultStudies/FondsConsultStudies';
import { PrivateMarketsRealEstate } from 'views/Modules/PrivateMarketsRealEstate/PrivateMarketsRealEstate';
import { RecommendationsForActions } from 'views/Modules/RecommendationsForActions/RecommendationsForActions';
import { MyWatchlist } from 'views/Modules/MyWatchlist/MyWatchlist';
import { SignUp } from 'views/User/SignUp/SignUp';
import { ComparisonList } from 'views/Modules/ComparisonList/ComparisonList';
import { MLPDepots } from 'views/Modules/MLPDepots/MLPDepots';

interface Route {
  path: string;
  component: FC;
  accessibility: Accessibility;
}

type Accessibility = 'TRIAL' | 'PRIVATE' | 'LOGGED OUT ONLY';

export const routes: Route[] = [
  {
    path: AppPaths.login,
    component: Login,
    accessibility: 'LOGGED OUT ONLY',
  },
  {
    path: AppPaths.main,
    component: MainPage,
    accessibility: 'PRIVATE',
  },
  {
    path: AppPaths.passwordResetRequest,
    component: PasswordResetRequest,
    accessibility: 'LOGGED OUT ONLY',
  },
  {
    path: AppPaths.resetPasswordLinkSent,
    component: ResetPasswordLinkSent,
    accessibility: 'LOGGED OUT ONLY',
  },
  {
    path: AppPaths.changePassword,
    component: ChangePassword,
    accessibility: 'LOGGED OUT ONLY',
  },
  {
    path: AppPaths.resetPasswordConfirmed,
    component: ResetPasswordConfirmed,
    accessibility: 'LOGGED OUT ONLY',
  },
  {
    path: AppPaths.contuctUs,
    component: ContactUs,
    accessibility: 'PRIVATE',
  },
  {
    path: AppPaths.esgCredibilityActionScore,
    component: ESGCredibilityActionScore,
    accessibility: 'PRIVATE',
  },
  {
    path: AppPaths.fcSmartFundBenchmarking,
    component: FCSmartFundBenchmarking,
    accessibility: 'PRIVATE',
  },
  {
    path: AppPaths.fondsConsultStudies,
    component: FondsConsultStudies,
    accessibility: 'PRIVATE',
  },
  {
    path: AppPaths.privateMarketsRealEstate,
    component: PrivateMarketsRealEstate,
    accessibility: 'PRIVATE',
  },
  {
    path: AppPaths.recommendationsForActions,
    component: RecommendationsForActions,
    accessibility: 'PRIVATE',
  },
  {
    path: AppPaths.myWatchlist,
    component: MyWatchlist,
    accessibility: 'PRIVATE',
  },
  {
    path: AppPaths.comparisonList,
    component: ComparisonList,
    accessibility: 'PRIVATE',
  },
  {
    path: AppPaths.mlpDepots,
    component: MLPDepots,
    accessibility: 'PRIVATE',
  },
  {
    path: AppPaths.signUp,
    component: SignUp,
    accessibility: 'LOGGED OUT ONLY',
  },
];
