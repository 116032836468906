import { FC } from 'react';
import { FondsDetailsProps } from './FondsDetails.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { format } from 'date-fns';
import {
  StyledInfoContainer,
  StyledInfoItem,
  StyledLink,
  StyledTopContainer,
  StyledTopItem,
  StyledTypography,
} from './FondsDetails.styled';
import { BoxWithBookmark } from '../BoxWithBookmark';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import {
  convertToMillions,
  formatCurrency,
  formatNumber,
  formatNumberWithComma,
} from 'utils/common';
import { COLORS } from 'theme/colors';
import { BasicTable } from '../BasicTable';
import { getFilterByValue } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/utils';
import {
  netFlows6MonthOptions,
  trackRecordOptions,
  volumeOptions,
} from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';
import { AppPaths } from 'urls/frontend';

const YES = 'Ja';
const NO = 'Nein';

export const FondsDetails: FC<FondsDetailsProps> = ({
  description,
  issuer,
  isin,
  name,
  peerGroup,
  editionDate,
  ongoing_charge,
  perfomanceFee,
  minimum_investment,
  min_investment_currency,
  distributing,
  sfdr,
  mifid,
  volume,
  volume_abs,
  volume_avg,
  track_record,
  track_record_abs,
  track_record_avg,
  net_flows_6_month,
  net_flow_6_month_avg,
  peerGroupId,
}) => {
  const topInfo = [
    {
      label: 'Fondsname',
      value: (
        <StyledTypography variant="body" textAlign="right">
          {name || ''}
        </StyledTypography>
      ),
    },
    {
      label: 'Peergroup',
      value:
        peerGroup && peerGroupId ? (
          <StyledLink
            to={`${AppPaths.fcSmartFundBenchmarking}?tab=Peergroups&peer_group=${peerGroupId}`}
          >
            {peerGroup}
          </StyledLink>
        ) : (
          <span>'-'</span>
        ),
    },
  ];

  const mainInfo = [
    { label: 'Fondsgesellschaft', value: issuer || '' },
    { label: 'ISIN', value: isin || '-' },
    {
      label: 'Auflagedatum',
      value: editionDate ? format(editionDate, 'dd.MM.yyyy') : '-',
    },
    {
      label: 'Laufende jährliche Kosten',
      value: ongoing_charge
        ? `${formatNumberWithComma(ongoing_charge)} %`
        : '-',
    },
    {
      label: 'Performance Gebühr',
      value: perfomanceFee ? `${perfomanceFee} %` : '-',
    },
    {
      label: 'Mindestanlage',
      value:
        minimum_investment && min_investment_currency
          ? formatCurrency(minimum_investment, min_investment_currency)
          : '-',
    },
    {
      label: 'Ausschüttend',
      value: distributing === null ? '-' : distributing ? YES : NO,
    },
    { label: 'SFDR Klassifizierung', value: sfdr || '-' },
    { label: 'MiFID Klassifizierung', value: mifid || '-' },
  ];

  const sfbInfo = [
    {
      label: 'Fondsvolumen',
      value: volume ? (
        <TableChip
          label={volume.toLowerCase() as ChipVariant}
          size="small"
          tableChipVaraint={
            getFilterByValue(volume, volumeOptions)?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
    {
      label: 'in Mio. Euro',
      value: volume_abs ? (
        <StyledTypography variant="body" weight="semibold">
          {convertToMillions(volume_abs)}
        </StyledTypography>
      ) : (
        '-'
      ),
    },
    {
      label: 'Durchschnitt Peergroup in Mio. Euro',
      value: volume_avg ? convertToMillions(volume_avg) : '-',
    },
    {
      label: 'Track Record',
      value: track_record ? (
        <TableChip
          label={track_record.toLowerCase() as ChipVariant}
          size="small"
          tableChipVaraint={
            getFilterByValue(track_record, trackRecordOptions)?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
    {
      label: 'in Jahren',
      value: track_record_abs ? (
        <StyledTypography variant="body" weight="semibold">
          {formatNumber(track_record_abs)}
        </StyledTypography>
      ) : (
        '-'
      ),
    },
    {
      label: 'Durchschnitt Peergroup in Jahren',
      value: track_record_avg ? (
        <StyledTypography variant="body" weight="semibold">
          {formatNumberWithComma(track_record_avg)}
        </StyledTypography>
      ) : (
        '-'
      ),
    },
    {
      label: 'Nettomittelzuflüsse der letzten 6 Monate',
      value: net_flows_6_month ? (
        <TableChip
          size="small"
          label={net_flows_6_month.toLowerCase() as ChipVariant}
          tableChipVaraint={
            getFilterByValue(net_flows_6_month, netFlows6MonthOptions)
              ?.chipVariant
          }
        />
      ) : (
        '-'
      ),
    },
    {
      label: 'Durchschnitt Peergroup in Mio. Euro',
      value: net_flow_6_month_avg
        ? convertToMillions(net_flow_6_month_avg)
        : '-',
    },
  ];

  return (
    <Box>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <StyledTopContainer px={2.5} pt={3} mt={2} pb={0.5}>
        {topInfo.map((item) => (
          <StyledTopItem
            key={item.label}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <StyledTypography variant="body" weight="semibold">
              {item.label}
            </StyledTypography>
            {item.value}
          </StyledTopItem>
        ))}
      </StyledTopContainer>
      {mainInfo.length !== 0 && (
        <StyledInfoContainer px={2.5} pb={3} mb={2} display="grid">
          {mainInfo.map((item) => (
            <StyledInfoItem
              key={item.label}
              display="flex"
              justifyContent="space-between"
              gap={1}
            >
              <StyledTypography variant="body" weight="semibold">
                {item.label}
              </StyledTypography>
              <StyledTypography variant="body" textAlign="right">
                {item.value}
              </StyledTypography>
            </StyledInfoItem>
          ))}
        </StyledInfoContainer>
      )}
      {sfbInfo.length !== 0 && (
        <BoxWithBookmark>
          <BasicTable
            px={2}
            pb={3}
            pt={1.5}
            columnNumber={3}
            rowNumber={3}
            data={sfbInfo.map((item) => ({
              label: (
                <StyledTypography variant="body" weight="semibold">
                  {item.label}
                </StyledTypography>
              ),
              value: (
                <StyledTypography variant="body">{item.value}</StyledTypography>
              ),
            }))}
          />
        </BoxWithBookmark>
      )}
    </Box>
  );
};
