import { TableCell, TableHead, styled as MUIstyled } from '@mui/material';
import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { hexToRGB } from 'utils/common';

export const StyledTableHead = MUIstyled(TableHead)({
  '& th': {
    '&:last-child': {
      paddingRight: 0,
    },
    '&:nth-child(2)': {
      position: 'sticky',
      left: 244,
      zIndex: 1,
      paddingLeft: '8px',
    },
  },
});

export const StyledSelectedFundsCell = MUIstyled(TableCell)({
  width: 210,
  position: 'sticky',
  left: 0,
  zIndex: 1,
  backgroundColor: COLORS.background.secondary,
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  border: `1px solid ${COLORS.stroke.primary}`,
  borderBottom: 0,
  verticalAlign: 'top',
  paddingTop: '22px',
  marginRight: '4px',
});

export const StyledFundsNumber = styled(Box)`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.background.neutral};
  border-radius: 50%;
  border: 1px solid ${hexToRGB(COLORS.info.selectedBorder, '0.1')};
  color: ${COLORS.info.selectedBorder};

  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
`;

export const StyledTableCell = MUIstyled(TableCell)({
  fontWeight: 'bold',
  padding: '0 4px',
  borderBottom: 0,
  width: 325,
});

export const StyledTableCellContainer = styled(Box)`
  background-color: ${COLORS.background.secondary};
  padding: 16px;
  height: 55px;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 1px solid ${COLORS.stroke.primary};
  border-bottom: 0;
`;
