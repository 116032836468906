import { ActiveManagement } from 'components/Modules/AssetClasses/ActiveManagement';
import { CrashDrawdown } from 'components/Modules/AssetClasses/CrashDrawdown';
import { ESG } from 'components/Modules/AssetClasses/ESG';
import { FondsDetails } from 'components/Modules/AssetClasses/FondsDetails';
import { FundManagementAlternativesETF } from 'components/Modules/AssetClasses/FundManagementAlternativesETF';
import { FundManagementBond } from 'components/Modules/AssetClasses/FundManagementBond';
import { FundManagementEquity } from 'components/Modules/AssetClasses/FundManagementEquity';
import { FundManagementMixed } from 'components/Modules/AssetClasses/FundManagementMixed';
import { IssuerRating } from 'components/Modules/AssetClasses/IssuerRating';
import { PeerGroup } from 'components/Modules/AssetClasses/PeerGroup';
import { Return } from 'components/Modules/AssetClasses/Return';
import { Risk } from 'components/Modules/AssetClasses/Risk';
import { SectorsRegions } from 'components/Modules/AssetClasses/SectorsRegions';
import { ShareClasses } from 'components/Modules/AssetClasses/ShareClasses';
import { SpecialFeaturesETF } from 'components/Modules/AssetClasses/SpecialFeaturesETF';
import { SpecialFeaturesMixedFonds } from 'components/Modules/AssetClasses/SpecialFeaturesMixedFonds';
import { SmartBenchmarkingFund } from 'types/SmartBenchmarking.d';

export const getTabsData = (fundData: SmartBenchmarkingFund) => {
  const {
    asset_class,
    issuer,
    isin,
    name,
    edition_date,
    min_investment,
    min_investment_currency,
    distributing,
    ongoing_charge,
    performance_fee,
    SFDR,
    MIFID_classification,
    track_record,
    volume,
    volume_abs,
    net_flows_6m,
    track_record_abs,
    peer_group,
    asset_class_related_data,
    return_score,
    return_pct,
    volatility,
    volatility_score,
    max_drawdown_score,
    drawdown_protection_score,
    crash_2007_2009,
    crash_2011,
    crash_2015_2016,
    crash_2018,
    crash_2020,
    crash_2022,
    esg_scores,
    tranches,
    data_state,
  } = fundData;

  const isMixed = asset_class === 'Mischfonds';
  const isETF = asset_class === 'ETFs';
  const isAlternative = asset_class === 'Alternatives';
  const isEquity = asset_class === 'Aktien';
  const isBond = asset_class === 'Renten';

  return [
    {
      label: 'Stammdaten',
      content: (
        <FondsDetails
          description="Neben den Stammdaten finden Sie hier eine relative Einordnung des Fonds im Rahmen unseres Smart Fund Benchmarking Ansatzes. Diese innovative Methode vergleicht die Fondsgröße, den Track Record und die Nettomittelzuflüsse mit relevanten Peers, um Ihnen eine fundierte Perspektive auf die Positionierung des Fonds im Markt zu ermöglichen."
          name={name}
          peerGroup={peer_group.name}
          peerGroupId={peer_group.id}
          issuer={issuer}
          isin={isin}
          editionDate={edition_date}
          ongoing_charge={ongoing_charge}
          perfomanceFee={performance_fee}
          minimum_investment={min_investment}
          min_investment_currency={min_investment_currency}
          distributing={distributing}
          sfdr={SFDR}
          mifid={MIFID_classification}
          volume={volume}
          volume_abs={volume_abs}
          volume_avg={peer_group.volume_avg}
          track_record={track_record}
          track_record_avg={peer_group.track_record_avg}
          track_record_abs={track_record_abs}
          net_flows_6_month={net_flows_6m}
          net_flow_6_month_avg={peer_group.net_flows_6m_avg}
        />
      ),
    },
    ...(isEquity
      ? [
          {
            label: 'Sektoren/Regionen',
            content: (
              <SectorsRegions
                regionOverweight={asset_class_related_data.overweighted_region}
                sectorOverweight={asset_class_related_data.overweighted_sector}
                regionUnderweight={
                  asset_class_related_data.underweighted_region
                }
                sectorUnderweight={
                  asset_class_related_data.underweighted_sector
                }
                description="In diesem Abschnitt erhalten Sie eine übersichtliche Darstellung der sektoralen und regionalen Gewichtungen des Fonds im Vergleich zum Durchschnitt seiner Peergruppe. Diese Gegenüberstellung ermöglicht es Ihnen, auf einen Blick zu erfassen, in welchen Bereichen der Fonds über- oder untergewichtet ist. So können Sie effizient die Unterscheidungsmerkmale und spezifischen Schwerpunkte des Fonds gegenüber seinen Mitbewerbern identifizieren."
              />
            ),
          },
        ]
      : []),
    ...(isETF
      ? [
          {
            label: 'Besonderheiten',
            content: (
              <SpecialFeaturesETF
                benchmarkName={asset_class_related_data.benchmark || '-'}
                activeManagement={asset_class_related_data.active_management}
                indexWeighting={asset_class_related_data.index_weighting}
                physicalReplication={
                  asset_class_related_data.physical_replication
                }
                securitiesLending={asset_class_related_data.securities_lending}
                description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem
      accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
      illo inventore veritatis et quasi architecto beatae vitae dicta sunt
      explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
      odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
      voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
      quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam
      eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
      voluptatem."
              />
            ),
          },
        ]
      : []),
    ...(isMixed
      ? [
          {
            label: 'Besonderheiten',
            content: (
              <SpecialFeaturesMixedFonds
                regionOverweight={asset_class_related_data.overweighted_region}
                sectorOverweight={asset_class_related_data.overweighted_sector}
                regionUnderweight={
                  asset_class_related_data.underweighted_region
                }
                sectorUnderweight={
                  asset_class_related_data.underweighted_sector
                }
                investment_grade={asset_class_related_data.investment_grade}
                high_yield={asset_class_related_data.high_yield}
                government_bonds={asset_class_related_data.government_bonds}
                corporate_bonds={asset_class_related_data.corporate_bonds}
                securitizations={asset_class_related_data.secularizations}
                description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem
      accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
      illo inventore veritatis et quasi architecto beatae vitae dicta sunt
      explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
      odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
      voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
      quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam
      eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
      voluptatem."
              />
            ),
          },
        ]
      : []),
    ...(isBond
      ? [
          {
            label: 'Rating/Emittent',
            content: (
              <IssuerRating
                investment_grade={asset_class_related_data.investment_grade}
                high_yield={asset_class_related_data.high_yield}
                government_bonds={asset_class_related_data.government_bonds}
                corporate_bonds={asset_class_related_data.corporate_bonds}
                securitizations={asset_class_related_data.secularizations}
                description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem
      accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
      illo inventore veritatis et quasi architecto beatae vitae dicta sunt
      explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
      odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
      voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
      quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam
      eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
      voluptatem."
              />
            ),
          },
        ]
      : []),
    ...(isBond
      ? [
          {
            label: 'Fondsmanagement',
            content: (
              <FundManagementBond
                averageCreditRating={asset_class_related_data.credit_rating_avg}
                yieldToMaturity={asset_class_related_data.yield_to_maturity}
                managementAffiliation={
                  asset_class_related_data.management_affiliation
                }
                modifiedDuration={asset_class_related_data.modified_duration}
                trackingError={asset_class_related_data.tracking_error}
                averageRemainingTerm={
                  asset_class_related_data.remaining_term_avg
                }
                description="Dieser Bereich bietet Ihnen einen anlageklassenspezifischen Überblick über das Fondsmanagement und den Investmentansatz. Sie finden hier wichtige Indikatoren wie die relative Managementzugehörigkeit, den Anlagestil und den Tracking Error - stets in Relation zum Peergruppen- Durchschnitt dargestellt. So bedeutet eine durchschnittliche Managementzugehörigkeit beispielsweise, dass der Fondsmanager den jeweiligen Fonds genauso lange verwaltet wie andere Fondsmanager innerhalb seiner Peer-Gruppe."
              />
            ),
          },
        ]
      : []),
    ...(isEquity
      ? [
          {
            label: 'Fondsmanagement',
            content: (
              <FundManagementEquity
                managementAffiliation={
                  asset_class_related_data.management_affiliation
                }
                trackingError={asset_class_related_data.tracking_error}
                description="Dieser Bereich bietet Ihnen einen anlageklassenspezifischen Überblick über das Fondsmanagement und den Investmentansatz. Sie finden hier wichtige Indikatoren wie die relative Managementzugehörigkeit, den Anlagestil und den Tracking Error - stets in Relation zum Peergruppen- Durchschnitt dargestellt. So bedeutet eine durchschnittliche Managementzugehörigkeit beispielsweise, dass der Fondsmanager den jeweiligen Fonds genauso lange verwaltet wie andere Fondsmanager innerhalb seiner Peer-Gruppe."
                marketCapitalization={
                  asset_class_related_data.market_capitalization_abs
                }
                marketCapitalizationConsistency={
                  asset_class_related_data.market_capitalization_consistency
                }
                assetConcentration={
                  asset_class_related_data.asset_concentration
                }
                valueToGrowthAbs={asset_class_related_data.value_to_growth_abs}
                valueToGrowthConsistency={
                  asset_class_related_data.value_to_growth_consistency
                }
              />
            ),
          },
        ]
      : []),
    ...(isMixed
      ? [
          {
            label: 'Fondsmanagement',
            content: (
              <FundManagementMixed
                shareQuota={asset_class_related_data.equity_ratio}
                managementAffiliation={
                  asset_class_related_data.management_affiliation
                }
                trackingError={asset_class_related_data.tracking_error}
                averageCreditRating={asset_class_related_data.credit_rating_avg}
                yeldToMaturity={asset_class_related_data.yield_to_maturity}
                averageRemainingTerm={
                  asset_class_related_data.remaining_term_avg
                }
                modifiedDuration={asset_class_related_data.modified_duration}
                marketCapitalization={
                  asset_class_related_data.market_capitalization_abs
                }
                marketCapitalizationConsistency={
                  asset_class_related_data.market_capitalization_consistency
                }
                assetConcentration={
                  asset_class_related_data.asset_concentration
                }
                valueToGrowthAbs={asset_class_related_data.value_to_growth_abs}
                valueToGrowthConsistency={
                  asset_class_related_data.value_to_growth_consistency
                }
                description="Dieser Bereich bietet Ihnen einen anlageklassenspezifischen Überblick über das Fondsmanagement und den Investmentansatz. Sie finden hier wichtige Indikatoren wie die relative Managementzugehörigkeit, den Anlagestil und den Tracking Error - stets in Relation zum Peergruppen- Durchschnitt dargestellt. So bedeutet eine durchschnittliche Managementzugehörigkeit beispielsweise, dass der Fondsmanager den jeweiligen Fonds genauso lange verwaltet wie andere Fondsmanager innerhalb seiner Peer-Gruppe."
              />
            ),
          },
        ]
      : []),
    ...(isETF || isAlternative
      ? [
          {
            label: 'Fondsmanagement',
            content: (
              <FundManagementAlternativesETF
                managementResponsibility={
                  isAlternative
                    ? asset_class_related_data.management_affiliation
                    : undefined
                }
                spread={isETF ? asset_class_related_data.spread : undefined}
                averageDailyTradingVolume={
                  isETF
                    ? asset_class_related_data.daily_trading_volume_avg
                    : undefined
                }
                costs={isETF ? asset_class_related_data.costs : undefined}
                description="Dieser Bereich bietet Ihnen einen anlageklassenspezifischen Überblick über das Fondsmanagement und den Investmentansatz. Sie finden hier wichtige Indikatoren wie die relative Managementzugehörigkeit, den Anlagestil und den Tracking Error - stets in Relation zum Peergruppen- Durchschnitt dargestellt. So bedeutet eine durchschnittliche Managementzugehörigkeit beispielsweise, dass der Fondsmanager den jeweiligen Fonds genauso lange verwaltet wie andere Fondsmanager innerhalb seiner Peer-Gruppe."
              />
            ),
          },
        ]
      : []),
    {
      label: 'Rendite',
      content: (
        <Return
          description="In diesem Abschnitt erhalten Sie eine schnelle und intuitive Einschätzung der historisch erzielten Rendite des Fonds. Auf einen Blick können Sie die Performance für kurz-, mittel- und langfristige Zeiträume erfassen. Zusätzlich präsentieren wir Ihnen die konkreten, annualisierten Renditen für die Zeiträume von 1 Jahr, 3 Jahren und 5 Jahren. Diese Darstellung ermöglicht Ihnen eine umfassende Beurteilung der Fondsperformance über verschiedene Zeithorizonte hinweg."
          standDate={data_state}
          annualizedData={[
            {
              categoryName: 'Fonds',
              values: {
                years1: return_pct?.year_1 || null,
                years3: return_pct?.year_3 || null,
                years5: return_pct?.year_5 || null,
              },
            },
            {
              categoryName: 'Durchschnitt Peergroup',
              values: {
                years1: peer_group?.return_pct.year_1 || null,
                years3: peer_group?.return_pct.year_3 || null,
                years5: peer_group?.return_pct.year_5 || null,
              },
            },
          ]}
          sfbData={[
            {
              categoryName: 'Rendite',
              values: {
                short: return_score?.short_term || null,
                medium: return_score?.medium_term || null,
                longTerm: return_score?.long_term || null,
              },
            },
          ]}
          trend={return_pct.trend}
        />
      ),
    },
    {
      label: 'Risiko',
      content: (
        <Risk
          description="Dieser Bereich bietet Ihnen eine prägnante und intuitive Übersicht über die Risikocharakteristika des Fonds. Auf einen Blick erfassen Sie die Volatilität, den maximalen Verlust (Maximum Drawdown) und die Downside Protection des Fonds. Wichtig zu beachten ist, dass es sich hierbei stets um eine relative Betrachtung handelt. Die Einschätzung erfolgt immer im Vergleich zum Peergruppen-Durchschnitt, was Ihnen eine aussagekräftige Einordnung des Fonds innerhalb seiner Vergleichsgruppe ermöglicht."
          standDate={data_state}
          annualizedData={[
            {
              categoryName: 'Fonds',
              values: {
                years1: volatility?.year_1 || null,
                years3: volatility?.year_3 || null,
                years5: volatility?.year_5 || null,
              },
            },
            {
              categoryName: 'Durchschnitt Peergroup',
              values: {
                years1: peer_group.volatility?.year_1 || null,
                years3: peer_group.volatility?.year_3 || null,
                years5: peer_group.volatility?.year_5 || null,
              },
            },
          ]}
          sfbData={[
            {
              categoryName: 'Volatilität',
              values: {
                short: volatility_score?.short_term || null,
                medium: volatility_score?.medium_term || null,
                longTerm: volatility_score?.long_term || null,
              },
            },
            {
              categoryName: 'Max Drawdown',
              values: {
                short: max_drawdown_score?.short_term || null,
                medium: max_drawdown_score?.medium_term || null,
                longTerm: max_drawdown_score?.long_term || null,
              },
            },
            {
              categoryName: 'Downside Protection',
              values: {
                short: drawdown_protection_score?.short_term || null,
                medium: drawdown_protection_score?.medium_term || null,
                longTerm: drawdown_protection_score?.long_term || null,
              },
            },
          ]}
          trend={volatility.trend}
        />
      ),
    },
    ...(isEquity || isBond || isAlternative
      ? [
          {
            label: 'Aktives Management',
            content: (
              <ActiveManagement
                title={isAlternative ? 'Gain/Loss Ratio' : 'Alpha'}
                description="Dieser Abschnitt vermittelt Ihnen einen präzisen Einblick in die Qualität des aktiven Managements des Fonds. Anhand des Alphas und der Information Ratio erhalten Sie eine aussagekräftige Indikation über die historische Performance des Fondsmanagers im Vergleich zum Peer- Gruppendurchschnitt. Die Darstellung erfolgt für kurz-, mittel- und langfristige Zeiträume und ermöglicht Ihnen so eine differenzierte Beurteilung der Fähigkeiten des Managements, Mehrwert zu generieren. Beachten Sie, dass alle Kennzahlen relativ zum Peergruppen- Durchschnitt präsentiert werden."
                standDate={data_state}
                annualizedData={[
                  {
                    categoryName: 'Fonds',
                    values: {
                      years1: isAlternative
                        ? asset_class_related_data.gain_loss_ratio?.year_1 ||
                          null
                        : asset_class_related_data.alpha?.year_1 || null,
                      years3: isAlternative
                        ? asset_class_related_data.gain_loss_ratio?.year_3 ||
                          null
                        : asset_class_related_data.alpha?.year_3 || null,
                      years5: isAlternative
                        ? asset_class_related_data.gain_loss_ratio?.year_5 ||
                          null
                        : asset_class_related_data.alpha?.year_5 || null,
                    },
                  },
                  {
                    categoryName: 'Durchschnitt Peergroup',
                    values: {
                      years1: isAlternative
                        ? peer_group.gain_loss_ratio?.year_1 || null
                        : peer_group.alpha?.year_1 || null,
                      years3: isAlternative
                        ? peer_group.gain_loss_ratio?.year_3 || null
                        : peer_group.alpha?.year_3 || null,
                      years5: isAlternative
                        ? peer_group.gain_loss_ratio?.year_5 || null
                        : peer_group.alpha?.year_5 || null,
                    },
                  },
                ]}
                sfbData={[
                  ...(isEquity || isBond
                    ? [
                        {
                          categoryName: 'Alpha',
                          values: {
                            short:
                              asset_class_related_data.alpha_score
                                ?.short_term || null,
                            medium:
                              asset_class_related_data.alpha_score
                                ?.medium_term || null,
                            longTerm:
                              asset_class_related_data.alpha_score?.long_term ||
                              null,
                          },
                        },
                        {
                          categoryName: 'Information Ratio',
                          values: {
                            short:
                              asset_class_related_data.information_ratio_score
                                ?.short_term || null,
                            medium:
                              asset_class_related_data.information_ratio_score
                                ?.medium_term || null,
                            longTerm:
                              asset_class_related_data.information_ratio_score
                                ?.long_term || null,
                          },
                        },
                      ]
                    : []),
                  ...(isAlternative
                    ? [
                        {
                          categoryName: 'Gain/Loss Ratio',
                          values: {
                            short:
                              asset_class_related_data.gain_loss_ratio_score
                                ?.short_term || null,
                            medium:
                              asset_class_related_data.gain_loss_ratio_score
                                ?.medium_term || null,
                            longTerm:
                              asset_class_related_data.gain_loss_ratio_score
                                ?.long_term || null,
                          },
                        },
                        {
                          categoryName: 'Calmar-Ratio',
                          values: {
                            short:
                              asset_class_related_data.calmar_ration_score
                                ?.short_term || null,
                            medium:
                              asset_class_related_data.calmar_ration_score
                                ?.medium_term || null,
                            longTerm:
                              asset_class_related_data.calmar_ration_score
                                ?.long_term || null,
                          },
                        },
                      ]
                    : []),
                ]}
                trend={
                  isAlternative
                    ? asset_class_related_data.gain_loss_ratio?.trend || null
                    : asset_class_related_data?.alpha?.trend || null
                }
              />
            ),
          },
        ]
      : []),
    {
      label: 'Crash Drawdowns',
      content: (
        <CrashDrawdown
          crash_2007_2009={crash_2007_2009}
          crash_2011={crash_2011}
          crash_2015_2016={crash_2015_2016}
          crash_2018={crash_2018}
          crash_2020={crash_2020}
          crash_2022={crash_2022}
          description="Dieser Bereich bietet Ihnen einen aufschlussreichen Überblick über das Verhalten des Fonds in verschiedenen Marktphasen und -krisen. Im Fokus steht der maximale Verlust des Fonds, stets im direkten Vergleich zum Peer- Gruppen-Durchschnitt. Die Darstellung ermöglicht Ihnen auf einen Blick zu erfassen, wie robust sich der Fonds in herausfordernden Marktphasen behauptet hat. Beachten Sie: Je niedriger der ausgewiesene Verlust, desto besser hat sich der Fonds relativ zu seiner Vergleichsgruppe in der jeweiligen Krisensituation geschlagen."
        />
      ),
    },
    {
      label: 'ESG',
      content: (
        <ESG
          totalScore={esg_scores?.credibility_score || null}
          esgCredibilityData={{
            organisationalOverview: esg_scores?.OO_CBM || null,
            policyGovernanceStrategy: esg_scores?.PGS || null,
            managerSelectionAppointmentMonitoring: esg_scores?.SAM || null,
            listedEquity: esg_scores?.LE || null,
            fixedIncome: esg_scores?.FI || null,
            realEstate: esg_scores?.RE || null,
            infrastructure: esg_scores?.INF || null,
            privateEquity: esg_scores?.PE || null,
            hedgefunds: esg_scores?.HF || null,
            transparency: esg_scores?.transparency || null,
          }}
          actionScoreFund={esg_scores?.action_score_fund || null}
          actionScoreFundCompany={esg_scores?.action_score_firm || null}
          sfdrData={[
            { label: 'Artikel 6', value: esg_scores?.article_6 || 0 },
            { label: 'Artikel 8', value: esg_scores?.article_8 || 0 },
            { label: 'Artikel 9', value: esg_scores?.article_9 || 0 },
          ]}
          description="In diesem Abschnitt präsentieren wir Ihnen den ESG Credibility Score, ein Ergebnis unseres proprietären ESG- Ansatzes. Dieser Score liefert eine aufschlussreiche Indikation darüber, wie glaubwürdig die Fondsgesellschaft ihre Nachhaltigkeitsbemühungen verfolgt („Top-Down“- Ansatz). Wichtig zu beachten ist, dass sich diese Bewertung auf die Fondsgesellschaft als Ganzes bezieht und nicht auf den einzelnen Fonds."
        />
      ),
    },
    {
      label: 'Anteilsklassen',
      content: <ShareClasses rows={tranches} />,
    },
    {
      label: 'Peergroup',
      content: (
        <PeerGroup
          description="Dieser Abschnitt bietet Ihnen einen konzentrierten Einblick in die Charakteristika der relevanten Peer-Gruppe. Hier finden Sie eine Zusammenstellung wichtiger Statistiken und Fakten, die Ihnen als Referenzpunkt für die Einordnung des betrachteten Fonds dienen. Die dargestellten Performance- und Risikokennzahlen repräsentieren die absoluten Durchschnittswerte der Peer-Gruppe. Diese Informationen ermöglichen Ihnen, die relative Positionierung des Fonds im Kontext seiner Vergleichsgruppe einzuschätzen."
          name={peer_group.name}
          alpha={peer_group.alpha}
          funds_num={peer_group.funds_num}
          net_flows_6m={peer_group.net_flows_6m}
          net_flows_6m_avg={peer_group.net_flows_6m_avg}
          return_pct={peer_group.return_pct}
          total_volume={peer_group.total_volume}
          track_record_avg={peer_group.track_record_avg}
          volatility={peer_group.volatility}
          volume_avg={peer_group.volume_avg}
        />
      ),
    },
  ];
};
