import { FC, useState } from 'react';
import { VerticalCollapseTableProps } from './VerticalCollapseTable.d';
import { TableContainer } from '@mui/material';
import { ReactComponent as ArrowCollapseIcon } from 'assets/Icons/collapse-arrow.svg';
import { COLORS } from 'theme/colors';
import {
  StyledCellContainer,
  StyledEmptyCell,
  StyledPaper,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from './VerticalCollapseTable.styled';
import { TableHead } from './TableHead/TableHead';
import { ExpandableRow } from './ExpandableRow';

export const VerticalCollapseTable: FC<VerticalCollapseTableProps> = ({
  isDifferencesHighlighted,
  tableData,
  pinnedColumn,
  showConfirmRemoveFromFundListModal,
  changeFundListStatus,
  showAddFeedbackModal,
  requestReport,
  showConfirmRemoveFromComparisonListModal,
  changePinnedColumn,
}) => {
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});

  const getFilteredColumns = () => {
    return pinnedColumn
      ? [
          pinnedColumn,
          ...tableData.columns.filter((item) => item.id !== pinnedColumn.id),
        ]
      : [...tableData.columns];
  };

  const handleToggle = (header: string) => {
    setOpen((prevOpen) => ({ ...prevOpen, [header]: !prevOpen[header] }));
  };

  return (
    <TableContainer component={StyledPaper} sx={{ overflowX: 'auto' }}>
      <StyledTable>
        <TableHead
          columns={getFilteredColumns()}
          requestReport={requestReport}
          showConfirmRemoveFromFundListModal={
            showConfirmRemoveFromFundListModal
          }
          changeFundListStatus={changeFundListStatus}
          showAddFeedbackModal={showAddFeedbackModal}
          showConfirmRemoveFromComparisonListModal={
            showConfirmRemoveFromComparisonListModal
          }
          changePinnedColumn={changePinnedColumn}
        />
        <StyledTableBody>
          {tableData.rows.map((row, index) => (
            <>
              <StyledTableRow key={`${row.categoryName}-${index}`}>
                <StyledTableCell
                  isOpen={open[row.categoryName]}
                  component="th"
                  scope="row"
                  align="left"
                  width="183px"
                  onClick={() => handleToggle(row.categoryName)}
                >
                  <StyledCellContainer isOpen={open[row.categoryName]}>
                    {row.categoryName}
                    <ArrowCollapseIcon
                      stroke={
                        open[row.categoryName]
                          ? COLORS.accent.primary
                          : COLORS.typography.main
                      }
                      style={{
                        transform: open[row.categoryName]
                          ? 'rotate(270deg)'
                          : 'rotate(360deg)',
                        width: '16px',
                        height: '16px',
                      }}
                    />
                  </StyledCellContainer>
                </StyledTableCell>
                <StyledEmptyCell
                  key={`${row.categoryName}-empty-row-${index}`}
                  isOpen={open[row.categoryName]}
                  align="left"
                  colSpan={getFilteredColumns().length}
                />
              </StyledTableRow>
              <ExpandableRow
                isDifferencesHighlighted={isDifferencesHighlighted}
                open={open}
                data={row.data}
                columns={getFilteredColumns()}
                row={row.categoryName}
              />
            </>
          ))}
        </StyledTableBody>
      </StyledTable>
    </TableContainer>
  );
};
