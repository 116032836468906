import styled from 'styled-components';
import { SlideOutModalProps } from './SlideOutFilterModal.d';
import { COLORS } from 'theme/colors';

export const StyledSlideOutModal = styled.div<SlideOutModalProps>`
  position: fixed;
  top: 0;
  right: -475px;
  width: 470px;
  height: 100vh;
  background-color: ${COLORS.background.secondary};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.4s ease;
  z-index: 20;
  display: flex;
  flex-direction: column;
  ${({ isShown }) =>
    isShown &&
    `
    right: 0;
  `}
`;

export const StyledModalHeader = styled.div`
  padding: 12.5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.stroke.primary};
`;

export const StyledCloseButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: unset;
  border: none;
  justify-self: end;

  &:hover {
    cursor: pointer;

    svg {
      stroke: ${COLORS.typography.caption};
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledModalContent = styled.div`
  padding: 20px;
  overflow-y: auto;
  flex: 1;
`;

export const StyledFooter = styled.div`
  padding: 19.5px 20px;
  background-color: ${COLORS.background.neutral};
  border-top: 1px solid ${COLORS.stroke.primary};
  display: flex;
  justify-content: space-between;
`;
