import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { RangeSliderProps } from './RangeSlider.d';
import {
  StyledInputLabel,
  StyledLabel,
  StyledSlider,
  StyledTextField,
} from './RangeSlider.styled';
import { Typography } from '../Typography';
import { COLORS } from 'theme/colors';
import { debounce } from 'utils/common';
import { forwardRef } from 'react';

export const RangeSlider = forwardRef<HTMLDivElement, RangeSliderProps>(
  ({ min, max, step = 10, label, currentValues, onChange }, ref) => {
    const [values, setValues] = useState<(number | '')[]>(
      currentValues || [min, max],
    );
    const [isFocused, setIsFocused] = useState(!!currentValues);
    const [isOutOfRange, setIsOutOfRange] = useState(false);

    const handleSliderChange = (_: Event, newValue: number | number[]) => {
      if (Array.isArray(newValue)) {
        setValues(newValue);
        if (onChange) {
          debouncedHandleSlider(newValue);
        }
      }
    };

    useEffect(() => {
      if (currentValues === undefined) {
        setValues([min, max]);
        setIsFocused(false);
      } else {
        setValues(currentValues);
      }
    }, [currentValues]);

    const handleChange = (newValue: number[]) => {
      if (Array.isArray(newValue)) {
        onChange && onChange(newValue);
      }
    };

    const handleFocus = () => {
      if (!isFocused) {
        setIsFocused(true);
      }
    };

    const debouncedHandleSlider = useCallback(debounce(handleChange, 500), []);

    const handleMinInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = event.target.value === '' ? '' : Number(event.target.value);
      setValues([value, values[1]]);
      setIsOutOfRange(
        value !== '' &&
          (value < min || (value as number) > (values[1] as number)),
      );
      if (onChange) {
        debouncedHandleSlider([value, values[1]]);
      }
      if (!isFocused) {
        setIsFocused(true);
      }
    };

    const handleMaxInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = event.target.value === '' ? '' : Number(event.target.value);
      setValues([values[0], value]);
      setIsOutOfRange(
        value !== '' &&
          ((value as number) < (values[0] as number) || value > max),
      );
      if (onChange) {
        debouncedHandleSlider([values[0], value]);
      }
      if (!isFocused) {
        setIsFocused(true);
      }
    };

    return (
      <Box ref={ref} sx={{ width: 430 }}>
        <StyledLabel variant="body">{label}</StyledLabel>
        <Box sx={{ width: '95%', margin: '0 auto' }}>
          <StyledSlider
            isFirstFocus={isFocused}
            value={values.map((value) => Number(value))}
            onChange={handleSliderChange}
            onFocus={handleFocus}
            min={min}
            max={max}
            step={step}
            valueLabelDisplay="off"
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2} mb={1}>
          <Box>
            <StyledInputLabel htmlFor="min-range-slider">Min</StyledInputLabel>
            <StyledTextField
              id="min-range-slider"
              isFirstFocus={isFocused}
              type="number"
              value={values[0]}
              onChange={handleMinInputChange}
              inputProps={{ min, max: values[1] }}
            />
          </Box>
          <Box>
            <StyledInputLabel htmlFor="max-range-slider">Max</StyledInputLabel>
            <StyledTextField
              id="max-range-slider"
              isFirstFocus={isFocused}
              type="number"
              value={values[1]}
              onChange={handleMaxInputChange}
              inputProps={{ min: values[0], max }}
            />
          </Box>
        </Box>
        {isOutOfRange && (
          <Typography
            variant="body"
            color={COLORS.error.main}
          >{`Der Wert liegt außerhalb des Bereichs ${min} - ${max}.`}</Typography>
        )}
      </Box>
    );
  },
);
